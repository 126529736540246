<template>
  <div class="lunch">
    <div class="container top-space-to-boxes">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://restaurang2valv.gastrogate.com/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch twoValve">
                  <div class="box-picture-text">2 Valv och kök</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">2 Valv och kök</div>
                 <p v-html="Lunch.TwoValveAndKitchenHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
         <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://frilaget.gastrogate.com/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch frilaget">
                  <div class="box-picture-text">Edge Kitchen</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">Edge Kitchen</div>
                    <div class="text-center">99 kr</div>
                    <p v-html="Lunch.EdgeKitchenHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://nilssonsrestaurang.gastrogate.com/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch nilssons">
                  <div class="box-picture-text">Nilssons</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">Nilssons</div>
                  <p v-html="Lunch.NilssonsHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
       <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://percysrestaurang.gastrogate.com/lunch/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch percys">
                  <div class="box-picture-text">Percys</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">Percys</div>
                  <p v-html="Lunch.PercysHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>-->
      <!--<div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://frilaget.gastrogate.com/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch frilaget">
                  <div class="box-picture-text">Friläget</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">Friläget</div>
                    <p v-html="Lunch.FrilagetHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <a target="_blank" href="https://frilaget.gastrogate.com/">
            <div class="box-two-third-container">
              <div class="box-two-third">
                <div class="box-picture lunch frilaget">
                  <div class="box-picture-text">Friläget</div>
                </div>
                <div class="box-two-third-text-wrapper">
                  <div class="box-two-third-header">Friläget</div>
                    <p v-html="Lunch.FrilagetHtmlTable"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import "../../src/styles/_layout.scss";
import "../../src/styles/_lunch-page.scss";

 const axios = require("axios");

export default {
  name: "Lunch",
  data: function () {
    return {
      Lunch: {
          TwoValveAndKitchenHtmlTable: "",
          PercysHtmlTable: "",
          NilssonsHtmlTable: "",
          FrilagetHtmlTable: "",
          EdgeKitchenHtmlTable: "",
      },
       //baseUrl: axios.defaults.baseURL,
       baseUrl:process.env.VUE_APP_WEB_API
    };
  },
  
  methods: {
        getLunch: function () {
                var self = this;
                axios.get(self.baseUrl + 'api/Lunch/GetLunch')
                    .then(function (response) {
                        self.Lunch.TwoValveAndKitchenHtmlTable = response.data.twoValveAndKitchenHtmlTable;
                        self.Lunch.NilssonsHtmlTable = response.data.nilssonsHtmlTable;
                        self.Lunch.FrilagetHtmlTable = response.data.frilagetHtmlTable;
                        self.Lunch.PercysHtmlTable = response.data.percysHtmlTable;
                        self.Lunch.EdgeKitchenHtmlTable = response.data.edgeKitchenHtmlTable;
                    })
                    .catch(error => {
                        console.log(error);
                        this.error = true;
                        alert(error);
                    });
        },
  },

   mounted() {
        this.getLunch();
    }  
}; 
</script>